import React from "react";
import { graphql } from "gatsby";
import { INLINES, BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { IoIosCalendar } from "react-icons/io";
import { GatsbyImage } from "gatsby-plugin-image";

import Seo from "../components/seo";
import { Container, Wrapper, Box } from "../components/util";
import { Breadcrumb, IframeContainer } from "../components/site";
import Layout from "../components/layout";

export const query = graphql`
  query($slug: String!) {
    contentfulMediaRelease(url: { eq: $slug }) {
      url
      date(formatString: "MMMM D, YYYY")
      title
      content {
        raw
      }
    }
  }
`;

const Release = (props) => {
  const website_url = process.env.GATSBY_SITE_URL;

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return (
          <GatsbyImage
            image={node.data.target.gatsbyImageData}
            alt={node.data.target.title}
            title={node.data.target.title}
          />
        );
      },
      [INLINES.HYPERLINK]: (node) => {
        if (node.data.uri.startsWith("#ID=")) {
          return (
            <span
              className="post-anchor"
              css={{ opacity: "0" }}
              id={node.data.uri.replace("#ID=", "")}
            >
              &nbsp;
            </span>
          );
        } else if (node.data.uri.includes("player.vimeo.com/video")) {
          return (
            <IframeContainer
              title="FlexiTime Vimeo Video"
              src={node.data.uri}
            />
          );
        } else if (node.data.uri.includes("youtube.com/embed")) {
          return (
            <IframeContainer
              title="FlexiTime YouTube Video"
              src={node.data.uri}
            />
          );
        } else {
          return (
            <a
              href={node.data.uri}
              target={`${
                node.data.uri.startsWith(website_url) ||
                node.data.uri.startsWith("/") ||
                node.data.uri.startsWith("#")
                  ? "_self"
                  : "_blank"
              }`}
              rel={`${
                node.data.uri.startsWith(website_url) ||
                node.data.uri.startsWith("/") ||
                node.data.uri.startsWith("#")
                  ? ""
                  : "noopener noreferrer"
              }`}
            >
              {node.content[0].value}
            </a>
          );
        }
      },
    },
  };

  return (
    <Layout>
      <Seo
        title={`${props.data.contentfulMediaRelease.title} | Media Release | FlexiTime`}
        description={`See the latest FlexiTime Media Releases and News. ${props.data.contentfulMediaRelease.title}.`}
        pathname={props.location.pathname}
      />
      <Breadcrumb
        parentPage="Media"
        parentPageLink="/media#releases"
        currentPage={props.data.contentfulMediaRelease.title}
      />
      <Container>
        <Wrapper maxWidth={850}>
          <Box className="-textCenter" stackGap={10}>
            <h4 title="Release Date" css={{ color: "#777" }}>
              <IoIosCalendar css={{ margin: "0 7px -3px 0" }} />
              {props.data.contentfulMediaRelease.date}
            </h4>
            <h1>{props.data.contentfulMediaRelease.title}</h1>
          </Box>
          <div className="font-bump">
            {renderRichText(props.data.contentfulMediaRelease.content, options)}
          </div>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default Release;
